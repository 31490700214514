import React, {useEffect, useState} from 'react';
import {Select, Tag} from "antd";
import apiRequest from "../general/apiRequest";

const apiUrlRightList                        = "/right/list"
const apiUrlRightListByRoleAndEntity         = "/right/listBy"
const apiUrlSaveRightToEntityRole            = "/right/saveRightTER"

const colorSet = [
  {id: "099ef0a7-249f-4d0a-a53a-1e252c8270c4", code: "L", color: "cyan"},//L cyan
  {id: "7312e725-5e73-4098-a474-17bad318ed3e", code: "U", color: "orange"},//U yellow
  {id: "9729d5d7-b5a0-4908-b5c4-6bc3c12588a2", code: "D", color: "red"},//D red
  {id: "9ad6d2f8-0988-429c-bf7e-85dd5d098ad0", code: "C", color: "yellow"},//C blue
  {id: "afd4301f-f9d0-42f7-aea3-4c4cfc038afc", code: "P", color: "lime"},//P magenta
  {id: "d6f1ea66-6f7a-4eb1-8ef2-2d217b3d000f", code: "R", color: "green"},//R green
]
export const SelectRights = ({entityId = "", roleId, readOnly, ...props}) => {
  const [rightList, setRightList] = useState([])
  const [entityRights, setEntityRights] = useState([])
  useEffect(() => {
    loadRightList()
    loadEntityRights()
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const loadRightList = () => {
    apiRequest.get(apiUrlRightList)
      .then((rd) => setRightList(rd.data))
      .catch(error => console.log("loadRightList in SelectRights: " + error))
  }
  const loadEntityRights = () => {
    if (roleId && entityId) {
      apiRequest.get(apiUrlRightListByRoleAndEntity + "/" + entityId + "/" + roleId)
        .then((rd) => setEntityRights(rd.data))
        .catch(error => console.log("loadEntityRights in SelectRights: " + error))
    }
  }
  const saveRights = () => {
    if (roleId && entityId) {
      apiRequest.put(apiUrlSaveRightToEntityRole + "/" + entityId + "/" + roleId, JSON.stringify(entityRights))
        .then((rd) => {
//
        })
        .catch(error => console.log("saveRights in SelectRights: " + error))
    }
  }
  const addRight = (value, opt) => {
    setEntityRights((prevState) => {
      const elements = prevState.find(e => e.id === value)
      if (!elements) prevState.push({id: value})
      return [...prevState]
    })
  }
  const delRight = (value) => {
    setEntityRights((prevState) => prevState.filter(e => e.id !== value))
  }
  const findColor = (value) => {
    if (value) {
      const ret = colorSet.find((e) => e.id === value).color
      if (ret) {return ret} else return "red"
    } else {
      return "green"
    }
  }
  const renderTag = ({ label, value, onClose, ...props}) => {
    const onPreventMouseDown = (event) => { event.preventDefault(); event.stopPropagation() }
    const color = findColor(value)
    return <Tag
      color={color}
      onMouseDown={onPreventMouseDown}
      onClose={onClose}
      style={{ marginRight: 3}}
      >
      {label}
    </Tag>
  }
  const getValue = () => {
    const value= entityRights.map( (item) => { return item['id'] })
    if (value) return value
    else return []
  }
  return <Select
    fieldNames={{label: "name", value: "id"}}
    {...props}
    popupMatchSelectWidth={true}
    mode="multiple"
    tagRender={renderTag}
    style={{ width: '100%' }}
    onBlur={!readOnly && saveRights}
    onSelect={!readOnly && addRight}
    onDeselect={!readOnly && delRight}
    value={getValue()}
    open={readOnly ? false : undefined}
    options={rightList}
  />
}
