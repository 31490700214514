import React, {useEffect, useState} from 'react'
import {Button, Popconfirm, Table} from "antd"
import {MinusCircleTwoTone, PlusCircleTwoTone, UserDeleteOutlined} from "@ant-design/icons"
import {RightChoose} from "../dialog/modalRightChoose";
import {SelectRights} from "../common/cmnRightSelect";
import apiRequest from "../general/apiRequest";

const apiUrlRoleList             = "/role/list"
const apiUrlEntityList           = "/entity/list"

export const RoleList = ({...props})=> {
  const [roles, setRoles] = useState([])
  useEffect( () => {
    loadRoles()
  }, [])
  const loadRoles = () => {
    apiRequest.get(apiUrlRoleList)
      .then((rd) => setRoles(rd.data))
      .catch(error => console.log("ContainerMenu in loadEdgeMenu: " + error))
  }
    return (
      <div>
        <h2>Rollen</h2>
        <Table
          pagination={{position: ['topRight', 'bottomRight'],}}
          rowKey="id"
          dataSource={roles}
          columns={[
            { title: "Name", dataIndex: "name", width: 100 },
            { title: ""},
            { title: <RightChoose />/*<Button type="primary" ghost={true} shape={"round"} onClick={() => {this.addRole()}}><UserAddOutlined  /></Button>*/,
              align: "right",
              render: (data, item, ix) => {return (
//                (data.tenantId !== "00000000-0000-0000-0000-000000000000")?
                <Popconfirm
                  placement="topRight"
                  title={"Möchten Sie die Rolle"}
                  onConfirm={() => {this.delRole(item)}}
                  okText="Ja"
                  cancelText="Nein"
                  cancelButtonProps={{danger: false, type: "primary"}}
                  okButtonProps={{danger: true, ghost: true}}
                >
                  <Button type="default"
                    danger
                    ghost={true}
                    shape={"round"}
                    disabled={data.tenantId === "00000000-0000-0000-0000-000000000000"}
                    icon={<UserDeleteOutlined/>}
                  />
                </Popconfirm>
//                :null
              )}
            },
          ]}
          expandable={{
            expandedRowRender: record => (
              <RoleRightList rollId={record.id} />
            ),
            expandIcon: ({ expanded, onExpand, record }) =>
              expanded ? (
                <MinusCircleTwoTone onClick={e => onExpand(record, e)} />
              ) : (
                <PlusCircleTwoTone onClick={e => onExpand(record, e)} />
              )

          }}
        />
      </div>
    )
}

const RoleRightList = ({...props})=> {
  const [entities, setEntities] = useState([])
  useEffect(() => {
    loadEntities()
  }, [])
  const loadEntities = () => {
    apiRequest.get(apiUrlEntityList)
      .then((rd) => {
        setEntities(rd.data)
      })
      .catch(error => console.log("loadEntities in RoleRightList: " + error))
  }
  const columns = [
    /*{ title: 'Rolle', dataIndex: 'roleName', key: 'name', width: 100 },*/
    { title: 'Entity', dataIndex: 'name', key: 'name', width: 100 },
    { title: 'Code',
//      dataIndex: 'rightcode',
      key: 'code',
      width: 180,
      render: (data, row, ix) => <SelectRights roleId={props.rollId} entityId={row.id} readOnly={false}/>
    },
    { title: ""},
  ]
  return (
    <Table
      rowKey="id"
      columns={columns}
      dataSource={entities}
      pagination={false}
    />
  )
}