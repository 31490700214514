/**
 * modal window to choose persons and organisations
 */

import React from 'react';
import {Button, Form, message, Modal, Tooltip} from "antd";
import {ExportOutlined} from "@ant-design/icons";
import {SelectCatalog} from "../common/cmnSelectCatalog";
import {TreeSelectCatalog} from "../common/cmnTreeSelectCatalog";
import {SelectYesNo} from "../common/cmnYesNoSelect";
import {TreeSelectBreakdown} from "../rascal/workpiece/cmnTreeSelectBreakdown";

const defaultChoose = {
  levels: [],
  materials:[],
  detailPlaning: null,
  conservation: null,
  cClassId: null,
  uClassId: null,
  usePlanning: true,
  useCapture: false,
}

export class GEABChoose extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
      ...defaultChoose
    }
//    const {buttonType, buttonIcon, buttonText, buttonGhost, buttonShape, onResult} = this.props
  }
  showModal = () => { this.setState({visible: true}) }
  handleCancel = () => { this.setState({visible: false}) }
  handleOk = () => {
    const {materials, detailPlaning, conservation, cClassId, uClassId, levels, usePlanning, useCapture} = this.state
    this.setState({visible: false})
    this.props.onResult({
      result: "ok",
      values: {
        levels: levels,
        conservation: conservation,
        detailPlaning: detailPlaning,
        cClassId: cClassId,
        uClassId: uClassId,
        materials: materials,
        usePlanning: usePlanning,
        useCapture: useCapture,
      }
    })
    this.setState({
      ...defaultChoose
      // levels: [],
      // materials: [],
      // detailPlaning: null,
      // conservation: null,
      // cClassId: null,
      // uClassId: null,
      // usePlanning: true,
      // useCapture: false,
    })
  }
  handleChanges = (name, value) => {
    this.setState({[name]: value})
  }
  // handleSwitchChanges = (name, value) => {
  //   this.setState({[name]: value})
  // }
  // handleSelectChanges = (name, value) => {
  //   this.setState({[name]: value})
  // }
  // handleTreeChanges = (name, value) => {
  //   this.setState({[name]: value})
  // }
  render() {
    const { visible } = this.state;
    return (
      <>
        <Tooltip title={"Erzeugt eine GAEB-Ausgabe"} color={'cyan'} placement="topRight">
          <Button
            type={this.props.buttonType}
            icon={this.props.buttonIcon}
            shape={this.props.buttonShape}
            onClick={this.showModal}
            />
        </Tooltip>
        <Modal
          destroyOnClose={true}
          title={<div style={{width: '100%'}}><h5>Auswahl</h5></div>}
          open={visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <Form name="gaeb-choose-form"
            colon=":"
            layout="horizontal"
            labelCol={{ span: 10 }}
            wrapperCol={{ span: 14 }}
          >
            <Form.Item label="Ebenen" name="levels" style={{marginBottom: 0}}>
              <TreeSelectBreakdown
                projectId={this.props.projectId}
                multiple={false}
                allowClear={false}
                value={this.state.levels}
                onChange={(tsData, tsLabel, tsExtra) => {this.handleChanges("levels", tsData)}}
                defaultActiveFirstOption={true}
                withParent={true}
                readOnly={false}
              />
            </Form.Item>
            <Form.Item label="Gewerk / Unterkategorie" name="materials" style={{marginBottom: 0}}>
              <TreeSelectCatalog
                catalogTree={"3b99bd67-b26a-4a78-ba6f-99f55442f9b9"}
                showempty={true}
                disableempty={false}
                textempty="--- keine Auswahl ---"
                multiple={true}
                allowClear={true}
                value={this.state.materials}
                onChange={(tsData, tsLable, tsExtra) => {this.handleChanges("materials", tsData)}}
              />
            </Form.Item>
            <Form.Item label="Detail-Fachplanung" name="detailPlaning" style={{marginBottom: 0}}>
              <SelectCatalog
                catalogGroup={"2da9a404-cf38-49d8-b24a-e1254a1502f5"}
                value={this.state.detailPlaning}
                onChange={(value) => {this.handleChanges("detailPlaning", value)}}
              />
            </Form.Item>
            <Form.Item label="Restaurierung" name="conservation" style={{marginBottom: 0}}>
              <SelectYesNo
                value={this.state.conservation}
                allowClear={true}
                onChange={(value) => this.handleChanges("conservation", value)}
              />
            </Form.Item>
            <Form.Item label="Zustandsklasse (CC)" name="cClassId" style={{marginBottom: 0}}>
              <SelectCatalog
                catalogGroup={"7357714A-2DF5-4806-9F02-4798DAF3F0E0"}
                value={this.state.cClassId}
                onChange={(value) => {this.handleChanges("cClassId", value)}}
              />
            </Form.Item>
            <Form.Item label="Dringlichkeitsklasse (UC)" name="uClassId" style={{marginBottom: 0}}>
              <SelectCatalog
                catalogGroup={"09c402e2-bf84-44b2-bc51-26a192e9d83c"}
                value={this.state.uClassId}
                onChange={(value) => {this.handleChanges("uClassId", value)}}
              />
            </Form.Item>
          </Form>
        </Modal>
      </>
    )
  }
}
GEABChoose.defaultProps = {
  buttonType: "link",
  buttonIcon: <ExportOutlined />,
  buttonText: "PDF Datenblatt...",
  buttonGhost: true,
  buttonShape: "default",
  onResult: (value) => {
    message.error(<p>Funktion nicht angelegt</p>,5)
  },
}