import React, {useEffect, useState} from "react"
import {Button, Dropdown} from "antd";
import {getEntityId} from "../general/Entities";
import apiRequest from "../general/apiRequest";
import {
  BugOutlined,
  ContactsOutlined,
  CopyOutlined,
  FileTextOutlined,
  GlobalOutlined,
  NumberOutlined,
  PhoneOutlined,
  PictureOutlined,
  RadarChartOutlined,
  WalletOutlined
} from "@ant-design/icons";
import {PersonChoose} from "../dialog/modalPersonChoose";

const apiUrlReferencedEdgeMetaTypesByOp         = "/panel/getReferencedEdgeMetaTypesByOp/8277798a-2a0b-11ed-a2b5-18cc1881fa4b"

const apiUrlPanelMenuItemEnabledTemp            = "/panelMenu"


const methodeName = {
  text:                   "Text hinzufügen",
  media:                  "Medien hinzufügen",
  measure:                "Maße hinzufügen",
  communication:          "Kommunikation hinzufügen",
  location:               "Geodaten hinzufügen",
  address:                "Adresse hinzufügen",
  subject:                "Kontakt hinzufügen",
  raveDamage:             "Schaden hinzufügen",
  raveMultiAssignment:    "Mehrfachzuweisung",
  raveRelocation:         "Inventarisierung",
  subjectPerson:          "Adresse",
  subjectOrganisation:    "Adresse",
  nodeData:               "mehr...",
}
const methodeIcon = {
  text:                   <FileTextOutlined />,
  media:                  <PictureOutlined />,
  measure:                <NumberOutlined />,
  communication:          <PhoneOutlined />,
  location:               <GlobalOutlined />,
  address:                <ContactsOutlined />,
  subject:                <ContactsOutlined />,
  subjectPerson:          <ContactsOutlined />,
  subjectOrganisation:    <ContactsOutlined />,
  raveDamage:             <BugOutlined />,
  raveMultiAssignment:    <CopyOutlined />,
  raveRelocation:         <WalletOutlined />,
  nodeData:               <RadarChartOutlined />,
}
const parentNodeMetaId = {
  tenant:               "f012f1fa-d32b-447b-a286-b1bd868728f4",
  project:              "63bbfd74-ac46-4f52-9599-71e41ffcd7cc",
  restorationgoal:      "1f26c1b0-017b-4e40-aad6-2a964230c783",
  breakdown:            "e5ef1268-ea3b-4e04-ad9c-7f7f91aa6282",
  room:                 "fc1a5bd0-9b05-404d-95ff-a40c923b924c",
  workpiece:            "071779d8-89c8-423a-b242-5fc2763d4c19",
  capture:              "bd5290d2-593d-437e-9c8b-ea523c75ae1a",
  planing:              "de77ecec-2b3d-49ff-b903-774054295298",
  restoration:          "b68cd461-8273-48d9-b701-1326c88ab42c",
  // image:                "image",
  // address:              "address",
}
const childNodeMetaId = {
  text:                   "4e38d4ad-0cdd-496a-9ffc-a489bd5a8765",
  media:                  "a7a54dc9-f079-400b-b60d-d8b21a866dc6",
  measure:                "47b9028c-a5b9-4041-bdbf-9ee935c1257d",
  communication:          "def1cd5e-a5b1-4433-8c2c-9b33acc19fdd",
  location:               "4af1b520-2cbd-43b3-b372-635111112f19",
  address:                "03aadaa7-c3f1-4eee-b0dd-ab641a2105a8",
  subject:                "5bdeb745-e91d-4585-84d2-69382344eee1",
  raveDamage:             "85859917-4c6f-4190-b92d-e338ebc1d515",
  raveMultiAssignment:    "560466b5-934f-4061-810c-06d2d54b34fa",
  raveRelocation:         "569ee7a4-b95a-4f2c-9fa2-6d44ae35073f",
  nodeData:               "5e6ea7a3-5a8e-418f-9c4a-697ec1f5869e",
//  subjectPerson:          "",
//  subjectOrganisation:    "",
}
export const ContainerMenu = ({panelChange, panelTypes, parentType, projectId, workId, newIndex, itemId, keyPart, ...props}) => {
  // const {panelTypes, parentType, workId/*, ...rest*/} = props
//  const {panelChange, panelTypes, parentType, projectId, workId, newIndex, itemId, keyPart} = props
  const [menuList, setMenuList] = useState([])
  const [edgeMenuList, setEdgeMenuList] = useState([])
  const [open, setOpen] = useState(false)
  let cmSubject = false
//  var cmSubject = false
  // useEffect(() => {
  //   loadEdgeMenu()
  //   buildMenuItems(panelTypes)
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [parentType, panelChange])
  const handleOpenChange = (nextOpen, info) => {
    if (nextOpen && menuList.length === 0) {
      loadEdgeMenu()
      buildMenuItems(panelTypes)
    }
    setOpen(nextOpen)
  }
  const loadEdgeMenu = () => {
    if (parentType) {
      apiRequest.get(`${apiUrlReferencedEdgeMetaTypesByOp}/${getEntityId(parentType)}`)
        .then((rd) => {
          if (rd && rd.state && rd.data) setEdgeMenuList(rd.data)
        })
        .catch(error => console.log("loadEdgeMenu in ContainerMenu: " + error))
    }
  }
  const buildEdgeMenuItem = (pt) => {
    const {projectId, workId, newIndex/*, itemId, keyPart*/} = props
    const position = newIndex
    if (edgeMenuList.length >= 0) {
      return edgeMenuList.map( edgeItem => {
        const key = pt + "_" + props.workId + "_" + edgeItem.id
        return {key: key, label: edgeItem.name, onClick: () => { onAdd({panelType: pt, nodeMetaId: edgeItem.id, parentId: workId, projectId, position})}}
      })
    }
    return null
  }
  const onAdd = (data) => {
    props.onAddPanel(data)
    buildMenuItems(panelTypes)
  }
  const buildMenuItems = (pts) => {
    cmSubject = false
    const testMenuList = pts.map((mPt) => buildMenuItem(mPt)).filter((item) => item !== null)
    // setMenuList(prevState =>  pts.map(pt => (
    //   buildMenuItem(pt))
    // ))
//    setMenuList((prevState) => pts.map((mPt) => buildMenuItem(mPt)).filter((item) => item !== null))
   setMenuList(testMenuList)
  }
  const buildMenuItem = (pt) => {
    const position = newIndex
    const key = pt + keyPart + itemId
    switch (pt) {
      case "text":
      case "media":
      case "measure":
      case "communication":
      case "location":
      case "raveDamage":
      case "raveMultiAssignment":
      case "address":
        return { key: key, icon: methodeIcon[pt], label: methodeName[pt], onClick: () => { onAdd({panelType: pt, nodeMetaId: null, parentId: workId, projectId: projectId, position: position})}}
      case "raveRelocation":
          return {
            key: key,
            label: <CustomItem
              label={methodeName[pt]}
              icon={methodeIcon[pt]}
              onClick={() => { onAdd({panelType: pt, nodeMetaId: null, parentId: workId, projectId: projectId, position: position})}}
              sourceNodeMetaId={parentNodeMetaId[parentType]}
              destNodeMetaId={childNodeMetaId[pt]}
              workId={workId}
              panelChange={panelChange}
            />
          }
      case "subjectPerson":
      case "subjectOrganisation":
        if (!cmSubject) {
          cmSubject = true;
          return { key: key,
            icon: methodeIcon["subject"],
            label: <PersonChoose
              onResult={(v) => {props.savePerson(v)}}
              panelTypes={new Set(props.panelTypes)}
              buttonText={methodeName[pt]}
              buttonType={"text"}
              buttonStyle={{paddingLeft:0}}
              buttonIcon={null}
            />,
          }
        } else return null

      case "nodeData":
        const emItems = buildEdgeMenuItem(pt)
        if (emItems.length > 0)
          return {key, icon: methodeIcon[pt], label: methodeName[pt], items: buildEdgeMenuItem(pt)}
        else return null
      default: return { key, icon: null, label: (methodeName[pt])?methodeName[pt]:"Empty", onClick: () => { window.alert(pt + "Not defined" )}}
    }
  }
  return <Dropdown
    menu={{items: menuList}}
    open={open}
    onOpenChange={handleOpenChange}
  >
    <Button type={"text"} icon={props.icon} />
  </Dropdown>
}


const CustomItem = ({panelChange, label, icon, onClick, sourceNodeMetaId, destNodeMetaId, workId, ...props}) => {
  const [customDisabled, setCustomDisabled] = useState(true)
//  const {panelChange, label, icon, onClick, sourceNodeMetaId, destNodeMetaId, workId, ...rest} = props
  useEffect(() => {
    checkState()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [panelChange])
  const checkState = () => {
    if (sourceNodeMetaId && destNodeMetaId && workId) {
      apiRequest.get(`${apiUrlPanelMenuItemEnabledTemp}/${sourceNodeMetaId}/${destNodeMetaId}/${workId}`)
        .then(rd => {
          if (rd && rd.state && rd.data) setCustomDisabled(prevState => !rd.data.active)
        })
        .catch(error => console.log("checkState in CustomItem: " + error))
    }
  }
  return <Button
    type={"text"}
    style={{paddingLeft:0}}
    icon={icon}
    {...props}
    disabled={customDisabled}
    onClick={onClick}
  >
    {label}
  </Button>
}