import React from 'react'
import {Tabs} from "antd"
import {RoleList} from "./baseRole";

//const routeSecurityShow         = "/api/security/show"

export const Security = ({...props}) => {
  return <div className="card">
    <div className="card-header">
      <h2>Rechte und Rollezuordnung</h2>
      <div>
        <Tabs
          destroyInactiveTabPane
          items={[{key: "SC1", label: "Rollen", destroyInactiveTabPane: true,children: <RoleList/>}]}
        />
      </div>
    </div>
  </div>
}
